<template>
  <div class="main-menu">
    <Background
      src="https://cdn.xr-academy.com/data/mobile-backgrounds/main-menu.jpg"
    ></Background>
    <div class="content">
      <div :style="style" class="center-image">
        <div class="text" :class="{ 'right-align': selectedLanguage == 'ar' }">
          <h1>
            {{ centerImage.title }}
          </h1>
          <h1>{{ centerImage.content }}</h1>
        </div>
      </div>
      <div
        v-if="Object.keys(buttons).length !== 0"
        class="menuButton_conatiner1"
        :class="{ 'menu-button-right': selectedLanguage == 'ar' }"
      >
        <MainMenuButton
          id="kingdom-of-living-things"
          :title="buttons.kingdomOfLivingThings"
          icon-height=""
          icon-width="70px"
          icon-left-position=""
          @click="navigateTo('kingdom-of-living-things')"
        ></MainMenuButton>
        <MainMenuButton
          id="human-body"
          :title="buttons.humanBody"
          icon-height=""
          icon-width="120px"
          icon-position="25px"
          @click="navigateTo('human-body')"
        ></MainMenuButton>
        <MainMenuButton
          id="space-science"
          :title="buttons.spaceScience"
          icon-height=""
          icon-width="120px"
          icon-position="10px"
          @click="navigateTo('space-science')"
        ></MainMenuButton>
      </div>
      <div
        class="menuButton_container2"
        :class="{ 'menu-button-left': selectedLanguage == 'ar' }"
      >
        <MainMenuButton
          id="art"
          :title="buttons.art"
          icon-height=""
          icon-width="70px"
          icon-position="-5px"
          @click="navigateTo('art')"
        ></MainMenuButton>
        <MainMenuButton
          icon-height=""
          icon-width="120px"
          icon-left-position=""
          id="cultural-heritage-history"
          :title="buttons.culturalHeritageHistory"
          @click="navigateTo('cultural-heritage-history')"
        ></MainMenuButton>
        <MainMenuButton
          id="archaeology"
          :title="buttons.archaeology"
          icon-height=""
          icon-width="90px"
          icon-left-position=""
          @click="navigateTo('archaeology')"
        ></MainMenuButton>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "../components/background.component.vue";
import MainMenuButton from "../components/main-menu-button.component.vue";
import { transitionTo, getGenericData } from "../utils/aframe.utils";
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";
import { sendPageView } from "../utils/dataCollection.utils";

export default {
  components: {
    Background,
    MainMenuButton,
  },
  data: () => ({
    buttons: {
      type: Object,
      default: {},
    },
    centerImage: {
      type: Object,
      default: {
        title: "",
        content: "",
      },
    },
    selectedLanguage: getSelectedLanguage(),
  }),
  methods: {
    navigateTo360Category(category) {
      this.navigateTo(`videos/${category}`);
    },

    navigateTo(commands) {
      transitionTo(commands.split("/"));
    },
  },
  computed: {
    style() {
      return `background-image: url('/images/main-menu/center.png');`;
    },
  },
  async created() {
    const { homeCenterImage, homeCategories } = await getGenericData();
    this.buttons = homeCategories;
    this.centerImage = homeCenterImage;
  },
  mounted() {
    sendPageView();
  },
};
</script>

<style scoped lang="scss">
.main-menu {
  .credit-card-content {
    margin-bottom: 14% !important;
  }
  .content {
    margin: 0;
    padding: 20vw 20px;
    font-size: 12px;

    .center-image {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: white;
      height: 520px;
      .text {
        padding: 10px 10px;
        h1 {
          &:nth-child(1) {
            font-size: 19px;
          }
          &:nth-child(2) {
            font-size: 33px;
          }
        }
      }
      .right-align {
        text-align: right;
      }
    }
    .header-component {
      width: 100%;
      padding-bottom: 10px;
    }
  }

  .header {
    width: 100%;
  }
  @media screen and (min-width: 500px) {
    .content {
      .center-image {
        height: 85%;
      }
    }
  }
  @media screen and (max-width: 385px) {
    .content {
      .center-image {
        height: 480px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .content {
      .center-image {
        height: 400px;
        .text {
          h1 {
            &:nth-child(2) {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 420px) and (orientation: landscape) {
    .content {
      display: flex;
      padding: 9vw 12px;

      .header {
        width: 30%;
        order: 2;
        margin: 1%;
      }
      .center-image {
        order: 2;
        margin: 6px 9px;
        height: unset;
        width: 46%;
        .text {
          h1 {
            &:nth-child(1) {
              font-size: 15px;
            }
            &:nth-child(2) {
              font-size: 26px;
            }
          }
        }
      }
      .menuButton_conatiner1 {
        display: flex;
        flex-direction: column;
        width: 56%;
        order: 3;
      }
      .menuButton_container2 {
        display: flex;
        flex-direction: column;
        width: 56%;
      }
      .menu-button-left {
        order: 3 !important;
      }
      .menu-button-right {
        order: 1 !important;
      }
    }
  }
  @media screen and (max-width: 668px) and (orientation: landscape) {
    .content {
      .center-image {
        //height: 261px;
        //width: 48%;
        .text {
          h1 {
            &:nth-child(1) {
              font-size: 13px;
            }
            &:nth-child(2) {
              font-size: 21px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) and (orientation: landscape) {
    .content {
      .center-image {
        height: 216px;
        .text {
          h1 {
            &:nth-child(2) {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}
</style>
