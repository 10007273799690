var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "credit_card_footer_main_container" }, [
      _c("div", { staticClass: "credit_card_footer_inner_container" }, [
        _c("div", { staticClass: "credit_card_details" }, [
          _c("p", { staticClass: "credit_card_address" }, [
            _vm._v(
              "\n        AMB GLOBAL DIGITAL SOLUTIONS LTD1 Maiou 3, 2610 Agios Epifanios\n        Oreinis, Cyprus\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "credit_card_link" }, [
            _vm._v("www.ambglobaldigital.com"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer_img" }, [
          _c("img", {
            attrs: { src: "/images/credit-card-footer/visa.png", alt: "visa" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "vertical_line" }),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: "/images/credit-card-footer/mastercard.png",
              alt: "mastercard",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }