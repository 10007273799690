<template>
  <div id="privacy-policy">
    <div v-if="privacyPolicyData !== null">
      <div class="privacy_policy_container">
        <h1 class="privacy_policy_header">{{ privacyPolicyData.title }}</h1>
        <div class="privacy_policy_details">
          <h3>{{ privacyPolicyData.ques1 }}</h3>
          <p>{{ privacyPolicyData.ans1 }}</p>
          <h3>{{ privacyPolicyData.ques2 }}</h3>
          <p>{{ privacyPolicyData.ans2 }}</p>
          <h3>{{ privacyPolicyData.ques3 }}</h3>
          <p>{{ privacyPolicyData.ans3 }}</p>
          <h3>{{ privacyPolicyData.ques4 }}</h3>
          <p>{{ privacyPolicyData.ans4 }}</p>
          <h3>{{ privacyPolicyData.ques5 }}</h3>
          <p>{{ privacyPolicyData.ans5 }}</p>
          <h3>{{ privacyPolicyData.ques6 }}</h3>
          <p>{{ privacyPolicyData.ans6 }}</p>
          <h3>{{ privacyPolicyData.ques7 }}</h3>
          <p>{{ privacyPolicyData.ans7 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";
import { getPrivacyPolicyText } from "../helpers/privacy-policy-page.data.helper";

export default {
  async created() {
    waitForLoaderToHide();
    this.privacyPolicyData = await getPrivacyPolicyText();
  },
  async mounted() {
    this.privacyPolicyData = await getPrivacyPolicyText();
  },
  data: () => ({
    privacyPolicyData: null,
  }),
};
</script>

<style lang="scss">
#privacy-policy {
  background-color: #efefef;
  cursor: auto;
  padding: 25px 30px;
  color: #3a3a3a;
  font-family: "Helvetica Neue";

  .privacy_policy_container {
    margin-top: 5%;
    .privacy_policy_header {
      font-size: 20px;
      font-weight: normal;
      line-height: 23px;
      margin-bottom: 5px;
      color: #3f3f3f;
      text-align: center;
    }
    .privacy_policy_details {
      margin-top: 4%;
      p {
        font-size: 14px;
        margin-bottom: 3%;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .privacy_policy_container {
      margin-top: 17%;
    }
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  #privacy-policy {
    height: unset;
    .privacy_policy_container {
      margin-top: 9%;
    }
  }
}
</style>
