import {
  domainConfig,
  loginPrimeHash,
  PRODUCT_XR_ACADEMY,
  PRODUCT_MOBIO_VR,
} from "./page-constants";
import { environmentConfig } from "../../environments/environment";

const getSubdomain = () => {
  const host = window.location.hostname;
  return host.split(".")[0];
};

const getProductName = () => {
  const host = window.location.hostname;
  if (host.split(".")[1] === PRODUCT_MOBIO_VR) return PRODUCT_MOBIO_VR;
  return PRODUCT_XR_ACADEMY;
};

const getCustomerCareInfo = () => {
  const subdomain = getSubdomain();
  const showCustomerCareLink =
    subdomain === "de" ||
    subdomain === "kw" ||
    subdomain === "om" ||
    subdomain === "ae" ||
    subdomain === "nl" ||
    subdomain === "ae" ||
    subdomain === "iq" ||
    subdomain === "ps" ||
    subdomain === "bh" ||
    subdomain === "pk";
  const customerCareLink = `https://${getProductName()}.com/customer-care?subdomain=${subdomain}`;
  return { showCustomerCareLink, customerCareLink };
};

const getHash = (subdomain, productName) => {
  const topLevelDomain = subdomain === "portal" ? "online" : "com";
  const host = `${subdomain}.${productName}.${topLevelDomain}`;
  const idx = loginPrimeHash.findIndex((item) => item.domain === host);
  return loginPrimeHash[idx].hash;
};

const getConfigs = (subdomain, product) => {
  // Default Config in case it is not found
  let config = domainConfig["demo"];
  let hash = getHash("demo", PRODUCT_XR_ACADEMY);
  const subdomains = Object.keys(domainConfig);
  const productName = product || getProductName();

  if (subdomains.indexOf(subdomain) >= 0) {
    config = domainConfig[subdomain];
    hash = getHash(subdomain, productName);
  }

  const pageConfigs = {
    ...config,
    productName,
    subdomain,
    hash,
    getCustomerCareInfo,
  };
  return pageConfigs;
};

export let config = {};

if (getSubdomain() === "localhost" || environmentConfig.production === false)
  config = getConfigs("demo", PRODUCT_XR_ACADEMY);
else config = getConfigs(getSubdomain());
