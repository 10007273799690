var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "privacy-policy" } }, [
    _vm.privacyPolicyData !== null
      ? _c("div", [
          _c("div", { staticClass: "privacy_policy_container" }, [
            _c("h1", { staticClass: "privacy_policy_header" }, [
              _vm._v(_vm._s(_vm.privacyPolicyData.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "privacy_policy_details" }, [
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques1))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans1))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques2))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans2))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques3))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans3))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques4))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans4))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques5))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans5))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques6))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans6))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.privacyPolicyData.ques7))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.privacyPolicyData.ans7))]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }