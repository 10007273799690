<template>
  <div id="help-center">
    <div class="help-center-container">
      <div class="help_details">
        <h1>Help Center</h1>
        <span>
          XR Academy is a subscription-based edutainment platform. We're here to
          revolutionise the global learning landscape. If you have any questions
          or need assistance, feel free to get in touch with our customer care
          team.
        </span>
        <div class="contact_container">
          <div class="contact_list">
            <img src="images/credit-card-ui/email.png" alt="email" />
            <p>help.xr-academy.online</p>
          </div>
          <div class="contact_list">
            <img src="images/credit-card-ui/phone-call.png" alt="phone" />
            <p>0 805 98 54 98 (France)</p>
          </div>
          <div class="contact_list">
            <img src="images/credit-card-ui/phone-call.png" alt="phone" />
            <p>900-751-051 (Spain)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";

export default {
  async created() {
    waitForLoaderToHide();
  },
};
</script>

<style lang="scss">
.help-center-container {
  color: #fff;
  margin-top: 11%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .help_details {
    margin: 0;
    padding: 2rem;
  }
  .contact_container {
    margin-top: 3%;

    .contact_list {
      display: flex;
      align-items: center;
      margin-left: 5%;
      margin-bottom: 2%;

      img {
        width: 25px;
      }

      p {
        margin: 0 0 0% 20px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .help_details {
      .contact_container {
        margin-top: 5%;

        .contact_list {
          margin-bottom: 6%;
        }
      }
    }
  }
}
</style>
