import { waitForLoaderToHide } from "../utils/scene.utils";
import { getState, getGenericData } from "../../vue/utils/aframe.utils";
import { isCreditCardProduct } from "../../vue/utils/isCreditCardProduct";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import creditCardFooter from "../../vue/components/credit-card-footer.component.vue";

AFRAME.registerComponent("main-menu-scene-controller", {
  events: {
    sceneEnter: async function (e) {
      const isRtl = getState().selectedLanguage.rtl;

      this.el.sceneEl.emit("clearBackLink");
      await waitForLoaderToHide();
      if (
        e.detail.firstScene &&
        !getState().debug &&
        !getState().cardboardMode
      ) {
        this.el.sceneEl.emit("playIntro");
      } else {
        this.el.sceneEl.emit("activateLookCamera", {
          animate: true,
          width: 12,
          height: 7,
          staticIfMobile: true,
          zoomIn: true,
        });

        setTimeout(() => {
          this.animateButtons(true);
        }, 1000);
      }

      const { homeCenterImage, homeCategories } = await getGenericData();

      this.el
        .querySelector(".cultural-heritage-history .title")
        .setAttribute("app-text", {
          value: homeCategories.culturalHeritageHistory,
          fontSize: isRtl ? "0.24" : "0.21",
        });
      this.el.querySelector(".art .title").setAttribute("app-text", {
        value: homeCategories.art,
        fontSize: isRtl ? "0.24" : "0.21",
      });
      this.el.querySelector(".archaeology .title").setAttribute("app-text", {
        value: homeCategories.archaeology,
        fontSize: isRtl ? "0.24" : "0.21",
      });

      this.el
        .querySelector(".kingdom-of-living-things .title")
        .setAttribute("app-text", {
          value: homeCategories.kingdomOfLivingThings,
          fontSize: isRtl ? "0.24" : "0.21",
        });

      this.el.querySelector(".human-body .title").setAttribute("app-text", {
        value: homeCategories.humanBody,
        fontSize: isRtl ? "0.24" : "0.21",
      });
      this.el.querySelector(".space-science .title").setAttribute("app-text", {
        value: homeCategories.spaceScience,
        fontSize: isRtl ? "0.24" : "0.21",
      });

      const xPosition = isRtl ? 1 : -1;
      this.el
        .querySelector(".main-menu-center-image-title")
        .setAttribute("position", { x: xPosition, y: 1.3, z: 0.1 });
      this.el
        .querySelector(".main-menu-center-image-content")
        .setAttribute("position", { x: xPosition, y: 0.65, z: 0.1 });

      this.el
        .querySelector(".main-menu-center-image-title")
        .setAttribute("app-text", {
          align: isRtl ? "right" : "left",
          anchor: isRtl ? "right" : "left",
          fontSize: isRtl ? "0.16" : "0.14",
          value: homeCenterImage.title,
        });

      this.el
        .querySelector(".main-menu-center-image-content")
        .setAttribute("app-text", {
          align: isRtl ? "right" : "left",
          anchor: isRtl ? "right" : "left",
          fontSize: isRtl ? "0.24" : "0.24",
          value: homeCenterImage.content,
        });
      if (isCreditCardProduct) {
        this.vueFooter = addVueComponent(creditCardFooter);
      }
    },

    sceneExiting: function () {
      this.animateButtons(false);
      if (this.vueFooter) {
        removeVueComponent(this.vueFooter);
      }
    },
  },

  animateButtons: function (active) {
    let delay = 0;

    Array.from(this.el.querySelectorAll(".main-menu-button")).forEach(
      (menuButton) => {
        const from = active ? 180 : 0;
        const to = active ? 360 : 180;

        menuButton.setAttribute("rotation", `0 ${from} 0`);
        menuButton.setAttribute("animation", {
          delay,
          easing: "linear",
          property: "object3D.rotation.y",
          from,
          to,
        });

        delay += 100;
      }
    );
  },
});
