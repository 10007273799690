import { getDesktopCamera } from "../utils/camera.utils";
import { getGenericData } from "../../vue/utils/aframe.utils";
import { config } from "../../vue/helpers/domain-config";

AFRAME.registerComponent("intro", {
  init: function () {
    this.transitionVideoLoaded = this.transitionVideoLoaded.bind(this);

    this.elBackground = this.el.querySelector(".background");
    this.elCanvas = this.el.sceneEl.canvas;
    this.elSceneMainMenu = document.getElementById("scene-main_menu");
    this.elIntro = document.getElementById("intro");
    this.elTransition = document.getElementById("transition-video");
    this.elTransitionVideo = this.elTransition.querySelector("video");
    this.elVue = document.querySelector("#vue");
    document.body.style.overflowY = "hidden";
    this.elCanvas.classList.add("hidden");
    this.elTransition.style.opacity = 0.4;
    this.elVue.classList.add("hidden-for-intro");
    if (config.subdomain === "portal")
      this.elVue.classList.add("style-for-credit-card");

    getGenericData().then(({ welcomeText }) => {
      document.querySelector(".welcome-to p").innerHTML = welcomeText;
    });

    const src = `https://cdn.xr-academy.com/data/videos/transition-${
      window.innerWidth < 640 ? "mobile" : "desktop"
    }.mp4?${Math.random()}`;
    this.elTransitionVideo.setAttribute("style", "opacity: 0");
    this.elTransitionVideo.setAttribute("src", src);
    this.elTransitionVideo.play();

    this.elTransitionVideo.addEventListener(
      "canplaythrough",
      this.transitionVideoLoaded
    );
  },

  transitionVideoLoaded: function () {
    this.elTransitionVideo.removeEventListener(
      "canplaythrough",
      this.transitionVideoLoaded
    );

    // if (this.elTransitionVideo.currentTime > 0) {
    this.elTransitionVideo.setAttribute("style", "opacity: 1");
    // }
    setTimeout(() => {
      this.elIntro.style.opacity = 1;
    }, 2000);

    let backgroundColorHack = false;
    this.backgroundColorInterval = setInterval(() => {
      try {
        this.elTransitionVideo.style.backgroundColor = backgroundColorHack
          ? "#000000"
          : "#000001";
        backgroundColorHack = !backgroundColorHack;
      } catch (e) {}
    }, 100);

    setTimeout(() => this.waitForTransition(), 5000);
  },

  waitForTransition: function () {
    this.loaded = false;
    this.transitionToMenu = this.transitionToMenu.bind(this);

    if (this.elBackground.components["load-asset"].assetLoaded) {
      this.transitionToMenu();
    } else {
      this.elBackground.addEventListener("assetLoaded", this.transitionToMenu);
    }
  },

  transitionToMenu: function () {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    setTimeout(() => {
      this.elCanvas.classList.remove("hidden");
      this.elIntro.style.opacity = 0;
      this.elTransition.style.opacity = null;
      this.elVue.classList.remove("hidden-for-intro");
      document.body.style.overflowY = "inherit";

      const dur = 4000;
      const easing = "easeInOutQuad";
      const zoom = 5;
      const elCamera = getDesktopCamera();
      elCamera.setAttribute("camera-zoom", "enabled", true);
      elCamera.setAttribute("animation__zoom-width", {
        property: "camera-zoom.width",
        dur,
        easing,
        from: 12 * zoom,
        to: 12,
      });

      elCamera.setAttribute("animation__zoom-height", {
        property: "camera-zoom.height",
        dur,
        easing,
        from: 12 * zoom,
        to: 7,
      });

      elCamera.setAttribute("animation__position", {
        property: "position.y",
        dur: dur - 250,
        easing: "easeInQuad",
        from: 3,
        to: 0,
      });

      elCamera.setAttribute("animation__rotation", {
        property: "rotation.x",
        dur: dur - 250,
        easing: "easeInQuad",
        from: -75,
        to: 0,
      });

      setTimeout(
        () => {
          this.elSceneMainMenu.components[
            "main-menu-scene-controller"
          ].animateButtons(true);
          this.el.sceneEl.systems["vue-ux"].showVueElements(true);
        },
        window.screen.width > 640 ? dur / 2 : 0
      );

      setTimeout(() => {
        this.el.sceneEl.emit("activateLookCamera", {
          width: 12,
          height: 7,
          staticIfMobile: true,
        });
        this.elTransition.parentElement.removeChild(this.elTransition);
      }, dur + 10);
      clearInterval(this.backgroundColorInterval);
    }, 1000);
  },
});
