var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-menu" },
    [
      _c("Background", {
        attrs: {
          src: "https://cdn.xr-academy.com/data/mobile-backgrounds/main-menu.jpg",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "center-image", style: _vm.style }, [
          _c(
            "div",
            {
              staticClass: "text",
              class: { "right-align": _vm.selectedLanguage == "ar" },
            },
            [
              _c("h1", [
                _vm._v(
                  "\n          " + _vm._s(_vm.centerImage.title) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h1", [_vm._v(_vm._s(_vm.centerImage.content))]),
            ]
          ),
        ]),
        _vm._v(" "),
        Object.keys(_vm.buttons).length !== 0
          ? _c(
              "div",
              {
                staticClass: "menuButton_conatiner1",
                class: { "menu-button-right": _vm.selectedLanguage == "ar" },
              },
              [
                _c("MainMenuButton", {
                  attrs: {
                    id: "kingdom-of-living-things",
                    title: _vm.buttons.kingdomOfLivingThings,
                    "icon-height": "",
                    "icon-width": "70px",
                    "icon-left-position": "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo("kingdom-of-living-things")
                    },
                  },
                }),
                _vm._v(" "),
                _c("MainMenuButton", {
                  attrs: {
                    id: "human-body",
                    title: _vm.buttons.humanBody,
                    "icon-height": "",
                    "icon-width": "120px",
                    "icon-position": "25px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo("human-body")
                    },
                  },
                }),
                _vm._v(" "),
                _c("MainMenuButton", {
                  attrs: {
                    id: "space-science",
                    title: _vm.buttons.spaceScience,
                    "icon-height": "",
                    "icon-width": "120px",
                    "icon-position": "10px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo("space-science")
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "menuButton_container2",
            class: { "menu-button-left": _vm.selectedLanguage == "ar" },
          },
          [
            _c("MainMenuButton", {
              attrs: {
                id: "art",
                title: _vm.buttons.art,
                "icon-height": "",
                "icon-width": "70px",
                "icon-position": "-5px",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("art")
                },
              },
            }),
            _vm._v(" "),
            _c("MainMenuButton", {
              attrs: {
                "icon-height": "",
                "icon-width": "120px",
                "icon-left-position": "",
                id: "cultural-heritage-history",
                title: _vm.buttons.culturalHeritageHistory,
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("cultural-heritage-history")
                },
              },
            }),
            _vm._v(" "),
            _c("MainMenuButton", {
              attrs: {
                id: "archaeology",
                title: _vm.buttons.archaeology,
                "icon-height": "",
                "icon-width": "90px",
                "icon-left-position": "",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("archaeology")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }